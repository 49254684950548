import React from 'react'
import { useState } from 'react';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import * as stylesSkill from './techStack.module.css';
import DropEffect, { dropShadowFuncs } from "./dropEffect/DropEffect";

const styleSxWrapSkill = {
    "&.wrapSkills": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: 'center',
        position: 'relative',

        "& .wrapSkill": {
            mx: [0.25, 0.5, 1],
            py: "0.25rem",
            backgroundColor: "unset",
            border: "none",
            " .drop": {
                transitionDuration: "0.5s",
                transitionTimingFunction: 'ease-in-out',

                "&.spin": {
                    // transitionTiming-function: ease;
                    // transition-delay: 0s;
                    transform: 'rotateZ(180deg)'
                },
            },
            "& .wrapTexts": {
                position: "absolute",
                top: "-100%",
                zIndex: '2',
                left: "10px",
                // width: "90%",
                maxWidth: "24rem",
                borderRadius: "0.25rem",
                borderWidth: "1px",
                borderColor: "primary2.dark",
                borderStyle: "groove",
                p: "0.5rem",

                bgcolor: "primary.main",
                color: "primary.contrastText",
                "& .wrapTitleDetails": {
                    whiteSpace: "pre-wrap",
                    m: 0,

                    "& .spanTitle": {
                        bgcolor: "primary2.main",
                        color: "primary2.contrastText",
                        "& .link": {
                            color: "unset",
                        },
                    },
                    "& .details": {
                        overflow: 'auto',
                        height: 'fit-content',
                        maxHeight: '150px',
                        // position: 'absolute',
                        top: '50px',
                        bgcolor: "primary.main",
                        // fontSize: "x-small",
                        fontSize: "small",
                    }
                },

                "&.invisible": {
                    visibility: "hidden",
                },
            },
            "& .wrapSvg": {
                cursor: "pointer",
                // fontSize: "1.875rem",
                lineHeight: "2.25rem",
                "& .svg": {
                    mr: "0.5rem",
                    height: ["2rem", "2.5rem", "3rem"],
                    width: ["2rem", "2.5rem", "3rem"],
                    fill: "currentColor",
                    pt: "0.25rem",
                    aspectRatio: '1',

                },
            },
        },
    }
}


type TechStackProps = {
    data: { title: string, textSk: string; svg?: JSX.Element | undefined; link: string; iconPath: string | undefined }[];
};

/**
 * Renders a component that displays a list of tech stack items.
 *
 * @param {TechStackProps} data - The data needed to render the tech stack items.
 * @return {ReactElement} The rendered component.
 */
const TechStack: React.FC<TechStackProps> = ({ data }: TechStackProps) => {
    const [lastElementOn, setLastElementOn] = useState<Element | null>(null);

    const toggleShowCard = (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
        data.forEach(({ title }) => {
            const currentElement = document.querySelector(`#${title}`);

            if (lastElementOn && lastElementOn.classList.contains('invisible')) {
                lastElementOn.classList.add('invisible');
            }
            const firstChild = currentElement?.firstChild as HTMLElement;
            if (e.currentTarget.getAttribute('id') === title) {
                firstChild?.classList.toggle('invisible');
                firstChild?.classList.toggle(stylesSkill.showDescr);
                currentElement?.firstChild && setLastElementOn(firstChild);
            } else {
                firstChild?.classList.add(`invisible`);
            }
        });
    };

    const techElements = data.map((element, index) => (
        <StackItem
            element={element}
            index={index}
            toggleShowCard={toggleShowCard}
            key={`${element.title}-${index}`}
        />
    ));

    return (
        <Box className="wrapSkills" sx={styleSxWrapSkill}>
            {techElements}
        </Box>
    );
};

export default TechStack;

/**
 * Renders a stack item button.
 *
 * @param {Object} props - The props for the component.
 * @param {Object} props.element - The stack item element.
 * @param {number} props.index - The index of the stack item.
 * @param {Function} props.toggleShowCard - The function to toggle show card.
 * @returns {JSX.Element} - The rendered stack item button.
 */
function StackItem({ element, index, toggleShowCard }: {
    element: {
        title: string;
        textSk: string;
        svg?: JSX.Element;
        link: string;
        iconPath: string | undefined;
    };
    index: number;
    toggleShowCard: (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void
}): JSX.Element {
    const { title, textSk, svg, link, iconPath } = element;
    const dataContent = `${title}. ${textSk}`;

    const toggleShowDescr = (e: React.MouseEvent<HTMLButtonElement>) => {
        const firstChild = e.currentTarget.firstChild as HTMLElement;
        firstChild.classList.toggle(stylesSkill.showDescr);
        firstChild.classList.toggle(stylesSkill.hideDescr);
        firstChild.classList.toggle("invisible");
    };

    const addSpin = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.currentTarget.lastElementChild?.classList.add("spin");
    };

    const removeSpin = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.currentTarget.lastElementChild?.classList.remove("spin");
    };

    return (
        <button
            id={title}
            data-content={dataContent}
            key={index}
            className="wrapSkill"
            title={title}
            //icon={title}
            onClick={toggleShowCard}
            onMouseEnter={(e: React.MouseEvent<HTMLButtonElement>) => {
                toggleShowDescr(e);
                addSpin(e);
            }}
            onMouseLeave={(e) => {
                toggleShowDescr(e);
                removeSpin(e);
            }}
        >
            <div
                className={`wrapTexts invisible ${stylesSkill.hideDescr}`}
            >
                <div
                    className="wrapTitleDetails"
                >
                    <span
                        className="spanTitle"
                    >
                        <a
                            href={link}
                            target="_blank"
                            rel="noreferrer"
                            className="link"
                        >
                            {title}
                        </a>
                    </span>
                    <div className="details">
                        {textSk}
                    </div>
                </div>
            </div>
            <DropEffect
                shadow={dropShadowFuncs.s2}
                styleDrop={{
                    padding: "20px",
                    width: "60px",
                    alignItems: 'unset',
                    justifyContent: 'unset',
                }}
            >
                <span
                    className="wrapSvg"
                >
                    {
                        !!svg ? svg :
                            <svg
                                role="img"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                className="svg"
                            >
                                <path d={iconPath} />
                            </svg>
                    }
                </span>
            </DropEffect>
        </button>
    );
}
